import { type Logger } from './logger';

const logLevelPriority: { [key: string]: number } = {
  silent: -1,
  debug: 0,
  info: 1,
  warn: 2,
  error: 3,
};

export const createConsoleLogger = (identifier: string | null = null, logLevel: string): Logger => {
  const isLogLevelEnabled = (level: string) => {
    const currentLevel = logLevelPriority[logLevel];
    return currentLevel >= 0 && logLevelPriority[level] >= currentLevel;
  };
  const withIdentifier = (msg: string): string => {
    return `[${identifier}]: ${msg}`;
  };
  const logWithArgs = (logFn: (...args: any[]) => void, ...theArgs: any[]): void => {
    if (typeof theArgs[0] === 'string') {
      // first argument is a string, extract that as our message
      // and delegate the remainder to the message context.
      const [msg, ...argsNoMsg] = theArgs;
      logFn(withIdentifier(msg), ...argsNoMsg);
    } else {
      logFn(withIdentifier('<no message>'), ...theArgs);
    }
  };

  return {
    debug: (...theArgs: any[]): void => {
      if (isLogLevelEnabled('debug')) {
        logWithArgs(console.debug, ...theArgs);
      }
    },
    info: (...theArgs: any[]): void => {
      if (isLogLevelEnabled('info')) {
        logWithArgs(console.info, ...theArgs);
      }
    },
    warn: (...theArgs: any[]): void => {
      if (isLogLevelEnabled('warn')) {
        logWithArgs(console.warn, ...theArgs);
      }
    },
    error: (...theArgs: any[]): void => {
      if (isLogLevelEnabled('error')) {
        logWithArgs(console.error, ...theArgs);
      }
    },
  };
};

/*
 * creates a logger that formats log messages to have an identifier prefix.
 * NOTE: c-style string formatting will not work with this logger.
 *       e.g. log.info("My name is %s and I am %d years old.", "john", 99);
 *       instead use string interpolation with backticks:
 *       log.info(`My name is ${name} and I am ${age} years old.`);
 */
export const namedConsoleLogger = (identifier: string, logLevel: string): Logger => {
  return createConsoleLogger(identifier, logLevel);
};
