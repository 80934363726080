import './NotFound.css';

function NotFound() {
  return (
    <div className="not-found">
      <div className="header">
        <div className="header-logo">
          <a href="https://projectsolar.com">
            <span className="sr-only">Project Solar homepage</span>
          </a>
        </div>
      </div>

      <div className="center-container">
        <h1 className="title">Oops! This page doesn't exist</h1>
        <p>It looks like this page has gone solar and left the grid.</p>
        <div className="back-on-track">
          <h2>Let's get you back on track</h2>
          <p>We're always here to assist you. Please reach out through:</p>
          <p>
            Email: <a href="mailto:support@projectsolar.com">support@projectsolar.com</a>
          </p>
          <p className="phone-contact">
            Phone: <a href="tel:256-669-9660">256 669-9660</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
