import { datadogLogArgs, remoteLogger } from './datadog-logger';
import { namedConsoleLogger } from './named-logger';

// escape hatch in case we need to disable remote logging
const isRemoteLoggingEnabled = process.env.REACT_APP_DD_LOG_ENABLED === 'true';

/**
 * centralized logging module. if you are going to log, use this!
 *
 * this module wraps 2 independant loggers: 1 remote, 1 local.
 * each logger can (internally) be configured separately; meaning,
 * for example, each can have their own logging level and message
 * formats.
 *
 * datadog is our current remote log service. check out the docs:
 * https://docs.datadoghq.com/logs/log_collection/javascript
 */

export interface Logger {
  debug: (...args: any[]) => void;
  info: (...args: any[]) => void;
  warn: (...args: any[]) => void;
  error: (...args: any[]) => void;
}

// default log levels
let localLogLevel = 'debug';
let remoteLogLevel = 'debug';

// set production logging levels
if (process.env.REACT_APP_ENV === 'prod') {
  localLogLevel = 'error';
  // NOTE: if logging in prod gets too noisy, we can lower the remote level
  remoteLogLevel = 'info';
}

const mainLogger = (name: string): Logger => {
  const localLog = namedConsoleLogger(name, localLogLevel);
  const remoteLog = isRemoteLoggingEnabled ? remoteLogger(name, remoteLogLevel) : null;

  return {
    debug: function debug(...theArgs: any) {
      try {
        localLog.debug.apply(localLog, [...theArgs]);
        if (remoteLog) {
          remoteLog.debug.apply(remoteLog, [...datadogLogArgs(theArgs)]);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error logging debug message', error);
      }
    },
    info: function info(...theArgs: any) {
      try {
        localLog.info.apply(localLog, [...theArgs]);
        if (remoteLog) {
          remoteLog.info.apply(remoteLog, [...datadogLogArgs(theArgs)]);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error logging info message', error);
      }
    },
    warn: function warn(...theArgs: any) {
      try {
        localLog.warn.apply(localLog, [...theArgs]);
        if (remoteLog) {
          remoteLog.warn.apply(remoteLog, [...datadogLogArgs(theArgs)]);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error logging warn message', error);
      }
    },
    error: function error(...theArgs: any) {
      try {
        localLog.error.apply(localLog, [...theArgs]);
        if (remoteLog) {
          remoteLog.error.apply(remoteLog, [...datadogLogArgs(theArgs)]);
        }
      } catch (error_) {
        // eslint-disable-next-line no-console
        console.log('error logging error message', error_);
      }
    },
  };
};

// // here's a quick way to test logging scenarios should changes be needed.
// // below are the scenarios used to fine-tune the datadog remote logs.
// const testLogger = mainLogger('testLogger');
// // works fine
// testLogger.info('simple text');
// // loses logger details; 2nd param gets converted to char array...shitty af
// testLogger.info('first of 2 strings', 'second string of 2 strings');
// testLogger.info('first param is string, second is number', 416);
// // gets logged as if "array.toString()"...yuck
// testLogger.info(['item 1 of basic array', 'item 2 of basic array']);
// // loses logger details; array gets merged into event attributes as index props...yuck
// testLogger.info('first param is string, 2nd is array', [
//   'array item 1',
//   'array item 2',
// ]);
// // gets logged as an object string...ugly af
// testLogger.info({ name: 'i am a simple object' });
// // gets logged but the http property gets blown away... wtf eh
// testLogger.info({ http: 'i am a simple object with http property name' });
// // logs message but http property gets blown away... holy fuck that's bad
// testLogger.info('logging an object with http prop', {
//   http: 'i am a simple object with http property name',
// });
// // logs first param as string but the 2nd gets merged into event attributes...yeah that sucks
// testLogger.info('logging a message with an object', {
//   name: 'i am an object as 2nd param',
// });
// testLogger.info(
//   'logging a message with multiple objects/array',
//   { name: 'steve' },
//   { age: 'old' },
//   [1, 2, 3],
// );
// testLogger.info(null, [{ name: 'foo' }]);
// testLogger.info([null, [{ name: 'foo' }]]);
// testLogger.info([null, null]);
// testLogger.info('logging a message with nested objects/arrays', {
//   names: [
//     {
//       first: 'ryan',
//       last: 'u',
//     },
//     {
//       first: 'steve',
//       last: 'd',
//     },
//   ],
//   users: [
//     {
//       sub: '8939024859027',
//       info: {
//         first: 'ryan',
//         last: 'u',
//       },
//       leads: [
//         {
//           id: '87239872398754skdufh',
//           address: '101 Dalmation Crescent',
//           names: [
//             {
//               first: 'ryan',
//               last: 'u',
//             },
//             {
//               first: 'steve',
//               last: 'd',
//             },
//           ],
//         },
//       ],
//     },
//   ],
// });

export function logger(name: string): Logger {
  return mainLogger(name);
}
