import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { logger } from './lib/logger';
import { init as initUserMonitor } from './lib/user-monitor';
import './index.css';
import NotFound from './NotFound';
import Root from './Root';
import Walkthrough from './Walkthrough';
import reportWebVitals from './reportWebVitals';

// log something early on so we can have a common
// message logged for all page loads; makes it a lot
// easier to query logs for unique page loads.
const log = logger('index');
log.info('initializing app');

initUserMonitor();

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: '/',
        element: <NotFound />,
      },
      {
        path: 'proposal-walkthrough/:id',
        element: <Walkthrough />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
