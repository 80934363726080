import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { logger } from './lib/logger';
import './Root.css';

const log = logger('Root');

function Root() {
  const location = useLocation();

  useEffect(() => {
    log.info(`location changed. path: ${location.pathname}`, { path: location.pathname });
  }, [location]);

  return (
    <div className="Root">
      <Outlet />
    </div>
  );
}

export default Root;
