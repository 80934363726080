import { datadogLogs, type StatusType } from '@datadog/browser-logs';
import { v4 as uuid } from 'uuid';

let isDatadogLogsInit = false;

// hot-reloading can trigger multiple initialize calls; let's just prevent that from happening
if (!isDatadogLogsInit) {
  isDatadogLogsInit = true;

  datadogLogs.init({
    clientToken: process.env.REACT_APP_DD_LOG_CLIENT_TOKEN || '',
    site: 'datadoghq.com',
    service: `siren-microsite-${process.env.REACT_APP_ENV || ''}`,
    forwardErrorsToLogs: true,
    sessionSampleRate: Number.parseInt(process.env.REACT_APP_DD_LOG_SAMPLE_RATE || '100'),
    env: process.env.REACT_APP_ENV,
    version: process.env.REACT_APP_DD_RELEASE_VERSION,
    // falls back to datadog domain if proxy not supplied or not enabled
    // ('false' value can be used as escape hatch if env variables can't be empty)
    proxy:
      process.env.REACT_APP_DD_PROXY_URL && process.env.REACT_APP_DD_PROXY_URL !== 'false'
        ? process.env.REACT_APP_DD_PROXY_URL
        : undefined,
  });

  datadogLogs.setLoggerGlobalContext({
    app: 'siren',
    // assign a unique id to all requests so we can track them in logs
    page_session_id: uuid(),
    // adding a "fake" host value is done purely for datadog display consistency;
    // all our other logs have a "HOST" value so we have a column in the logs view
    // which is nicer to view when all records have a host value.
    host: window.origin,
  });
}

/**
 * converts the supplied array of arguments into a structure suitable
 * for sending to datadog as arguments. datadog accepts a specific
 * interface and we need to accomodate.
 */
export const datadogLogArgs = (theArgs: any[]): [string, Record<string, unknown>] => {
  // https://docs.datadoghq.com/logs/log_collection/javascript/#custom-logs
  // datadog accepts logs in the form of `message: string, context: Object`
  // so we need to inspect the arguments in order to restructure the log
  // to suit datadog.

  // in case our first argument is not a string we assign a default log message;
  // datadog requires a message parameter.
  let message = '<no message>';
  // assume the message context is the entire set of arguments
  let messageContextItems = theArgs;

  // NOTE: need to study logs more to see which approach is preferred here...
  // allow for quickly toggling between inlining all strings when there
  // are only string arguments; need to study the logs to see which is
  // preferred: inlining or attaching the extra strings to context.
  const inlineIfAllStrings = true;

  const allArgsAreStrings =
    inlineIfAllStrings && theArgs.filter((x) => typeof x !== 'string').length === 0;
  if (allArgsAreStrings) {
    // all arguments are strings, we can concat them all together
    // since that makes the most sense for displaying strings.
    message = theArgs.join(' ');
    messageContextItems = [];
  } else if (typeof theArgs[0] === 'string') {
    // first argument is a string, extract that as our message
    // and delegate the remainder to the message context.
    const [msg, ...argsNoMsg] = theArgs;
    message = msg;

    // NOTE: need to study logs more to see which approach is preferred here...
    // not 100% sure if the message portion of the args should _also_ be
    // included with the message context; leaving this flag here to make
    // it convenient to flip back-n-forth.
    const includeMessageInContext = false;

    messageContextItems = includeMessageInContext ? messageContextItems : argsNoMsg;
  }

  // build the final log context object by rooting our context
  // in an object type (converting the args array as needed).
  const messageContextObject: { [x: number]: any } = {};
  if (messageContextItems.length > 1) {
    // if we received multiple arguments then we should create
    // an object from them using the item index as it's key.
    // we do this because datadog doesn't visualize or filter
    // data (nicely anyway) when it's not in an object structure
    // at the root (meaning if we had an array of objects as our
    // root then it would not display/filter nicely).
    for (const [i, messageContextItem] of messageContextItems.entries()) {
      messageContextObject[i] = messageContextItem;
    }
  } else if (messageContextItems.length > 0) {
    // ok, just a single arg left, extract it from the array and
    // assign it to the 0-index property of context.
    [messageContextObject[0]] = messageContextItems;
    // we can also assign the single arg as the root context
    // object BUT if the arg is an array (for example) then the
    // context also becomes an array; for now i decided to always
    // enforce the context being an object.
    // [messageContextObject] = messageContextItems;
  }

  return [message, { _context: messageContextObject }];
};

/*
 * creates a datadog logger
 * NOTE: c-style string formatting will not work with this logger.
 *       e.g. log.info("My name is %s and I am %d years old.", "john", 99);
 *       instead use string interpolation with backticks:
 *       log.info(`My name is ${name} and I am ${age} years old.`);
 */
export const remoteLogger = (name: string, logLevel: string) => {
  const dLogger = datadogLogs.createLogger(name, {
    level: logLevel.toLowerCase() as StatusType,
    // note that datadog _can_ log to console as well BUT the format that
    // it logs is pretty shitty and not configurable so i opted to log
    // remote only and implement a different console/local logger.
    handler: 'http', // options can be 'http', 'console', ['http', 'console']
  });
  return dLogger;
};
